define('ember-changeset/helpers/changeset', ['exports', 'ember-changeset', 'ember-changeset/utils/is-changeset', 'ember-changeset/utils/is-promise', 'ember-changeset/utils/is-relay'], function (exports, _emberChangeset, _isChangeset, _isPromise, _isRelay) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.changeset = changeset;


  /*::
  import type { ValidatorFunc } from 'ember-changeset/types/validator-func';
  import type { Config } from 'ember-changeset/types/config';
  */

  // @flow

  function changeset([obj, validations] /*: [Object, ValidatorFunc] */
  , options /*: Config */ = {}) {
    if ((0, _isChangeset.default)(obj)) {
      return obj;
    }

    if ((0, _isPromise.default)(obj)) {
      return obj.then(resolved => new _emberChangeset.default(resolved, validations, {}, options));
    }

    let result = new _emberChangeset.default(obj, validations, {}, options);
    if ((0, _isRelay.default)(result)) {
      return Ember.get(result, 'content');
    }
    return result;
  }

  exports.default = Ember.Helper.helper(changeset);
});